angular.module('app', [
  'ngRoute',
  'ngCookies',
  'ngAnimate',
  'ngResource', 
  'ngSanitize',
  'app.controllers',
  'app.resources',
  'app.services',
  'app.directives',
  'app.filters',
  'mwTable',
  'slickCarousel',
  'angular-loading-bar',
  'infinite-scroll',
  'checklist-model'
])

  .run([
    '$rootScope', '$route', '$routeParams', 'smoothScr', '$cookies', '$location', '$timeout', 'APICheckin', 'APIChats', 'APISettings', 'UI'
  ].append (root, $route, $routeParams, smoothScr, $cookies, $location, $timeout, APICheckin, APIChats, APISettings, UI) ->
    
    root.overlay    = false
    root.pageLoaded = false
    root.settings   = null
    root.state      = null
    root.substates  = []
    root.preview    = false
    root.isSearch   = false

    root.setting = (skey) ->
      setting = root.settings?.find (setting) -> return setting.skey == skey
      return setting?.svalue || null

    root.isState = (key = null) ->
      return false unless key?
      return key == root.state || key in root.substates

    root.setState = (state) ->
      root.state = state

    root.setSubstate = (substate) ->
      root.substates.push(substate) if substate not in root.substates

    root.$on 'updateSetting', (event, skey, svalue) ->
      root.settings = root.settings.map((item) ->
        item.svalue = svalue if item.skey == skey
        return item
      )

    #
    # first of all load default event settings
    #

    APISettings.query {shortcode: 'settings', scategory: 'general'}, (data) ->
      root.settings = data      
      # set app time depend event timezone
      root.$broadcast 'fetchTime'
      # get session data
      root.$emit 'proveSession'
    , (response) ->
      UI.notify false, "Can't laod application settings"

    #
    # Provide loading state for templates and controllers
    #
    
    root.loading = false
    root.$on 'cfpLoadingBar:started',   -> root.loading = true
    root.$on 'cfpLoadingBar:completed', -> root.loading = false
    
    #
    # Modals
    #

    lockModalEl    = document.getElementById('lockModal')
    root.lockModal = bootstrap.Modal.getOrCreateInstance(lockModalEl) if lockModalEl?

    onModalEl    = document.getElementById('onModal')
    if onModalEl?
      root.onModal = bootstrap.Modal.getOrCreateInstance(onModalEl)

      onModalEl.addEventListener('show.bs.modal', (event) ->
        root.$broadcast 'openOnboardModal'
        root.$applyAsync()
      )

    #
    # Menu and highlight
    #

    menuOffcanvasEl = document.getElementById('mobileMenu')
    menuOffcanvas   = new bootstrap.Offcanvas(menuOffcanvasEl) if menuOffcanvasEl?
    
    root.option = (name) ->
      return null unless $route.current?.$$route?.options?
      return null unless $route.current.$$route.options[name]?
      $route.current.$$route.options[name]

    root.at = (menu) ->
      root.menu is menu

    root.get = (param) ->
      $routeParams[param]
      
      
    root.closeMenu = () ->
      subnavContainer = angular.element('.subnav')
      subnavContainer.each (index, element) ->
        element.style.display = 'none'
      $timeout ->
        subnavContainer.each (index, element) ->
          element.style.display = null
      , 200
      
      # Find and click the navbar-toggler button to close the mobile menu
      navbarToggler = angular.element('.navbar-toggler')
      if navbarToggler.length > 0 && navbarToggler.attr('aria-expanded') == 'true'
        navbarToggler.click()

    #
    # Update menu item
    #
    
    root.$on '$routeChangeSuccess', (event, current, previous) ->  
      root.menu = ''
      root.menu = root.option 'menu' if root.option 'menu'
      root.closeMenu()
      $timeout () -> smoothScr.scroll('#body-top', 0)


    routeReload = false

    root.$on '$routeChangeStart', (event, next, current) ->      
      routeReload = false

      # wait loading required data and stop routing
      if !root.pageLoaded || !root.state?
        routeReload = true
        return event.preventDefault()

      menuOffcanvas.hide() if menuOffcanvas?

      nextPath = next?.$$route?.originalPath || '/logout'

      protectedPath = ['/profil'] #'/aussteller/:id', '/jobs/:id-:entry_id', '/neuheiten/:id-:entry_id', '/aussteller/:id/produkte/:entry_id', '/aussteller/:id/marken/:entry_id'

      availablePath = [] #'/soon', '/testlighting'

      # if not in public pages
      if nextPath in protectedPath
        unless root.user?.auth_ticket
          event.preventDefault()
          if current?
            root.lockModal.show()
            return event.preventDefault()
          else
            return $location.path('') #soon

        if root.user?.role == 'exhibitor'
          return root.logout(true, 1) unless next.params?.id?
          if root.session?.user?.id?
            return root.logout(true, 2) unless parseInt(next.params.id) == root.session.user.id
      else
        if availablePath.length > 0 && nextPath not in availablePath      
          event.preventDefault()
          return $location.path('/soon')

        if nextPath in ['/registrierung', '/login', '/passwort-confirm', '/passwort-vergessen', '/auth'] && root.user?.auth_ticket
          return $location.path('/')

        #if nextPath == '/' && root.user?.auth_ticket
        #  return $location.path('/home')

        if root.user?.role == 'exhibitor'
          return root.logout(true, 3) unless next.params?.id?
          if root.session?.user?.id?
            return root.logout(true, 4) unless parseInt(next.params.id) == root.session.user.id

    #
    # Session data
    #

    root.session  = null
    root.$on 'proveSession', (event, redirect = false) -> 
      
      root.user  = $cookies.getObject('user') || null
      root.tuser = $cookies.get('tuser') || null
      
      locPath   = $location.path()
      locParams = $location.search()

      if locParams.auth_ticket && String(locPath).indexOf('aussteller/') != -1

        root.user = 
          auth_ticket: locParams.auth_ticket
          role: 'exhibitor'

        # preview mode ON but check if ticket is exhibitor after getting details
        root.preview = true

      unless root.user?.auth_ticket?
        $route.reload() if routeReload
        
        unless root.tuser?
          root.tuser = Date.now()
          $cookies.put('tuser', root.tuser)
        
        root.$broadcast 'getFavorites', 'proveTemp'
        
        return root.pageLoaded = true

      APICheckin.get {auth_ticket: root.user.auth_ticket, _private: true}, (data) ->

        root.session = data        

        root.user.shortcode = data.shortcode unless root.user.shortcode
        root.user.role      = data.role

        $cookies.putObject('user', root.user)

        # extend settings with user options
        APISettings.query {shortcode: root.user.shortcode, scategory: root.user?.role || 'general'}, (data) ->
          angular.extend root.settings, data
        , (response) ->
          UI.notify false, response.data.error

        root.preview = false
          
        if root.user.role == 'exhibitor'
          root.preview = true

          if root.get('id')
            return root.logout(true, 'session exhibitor') unless parseInt(root.get('id')) == data.user.id

        root.pageLoaded = true

        root.$broadcast 'getFavorites', 'proveSession'
        
        $timeout () ->
          root.onModal.show() unless root.session.user.settings?.additional_field?.additional_field_value_3
        , 500  

        return $route.reload() if routeReload
        
        if redirect
          if root.at('home')
            return $route.reload()
          else
            return $location.path('/') 
    
      , (response) ->
        UI.notify false, response.data.error

        root.logout('true', 'cant get session')

    root.logout = (redirect = true, trigger = 'test') ->

      root.preview  = false
      root.user     = null
      root.session  = null

      root.settings = root.settings.filter (item) -> return item.shortcode == 'settings'

      $cookies.remove 'user'

      root.$emit 'proveSession'

      root.$broadcast 'resetCounters'

      $location.path('/logout') if redirect
      
      root.$applyAsync()
  )   

  .config ['$routeProvider', '$locationProvider', 'cfpLoadingBarProvider', '$qProvider', '$cookiesProvider', '$animateProvider', (r, l, c, q, co, a) ->

    a.classNameFilter(/animate-/)

    moment.locale('de')
    
    now = new Date()
    co.defaults.expires = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 31)

    q.errorOnUnhandledRejections(false)
    
    l.html5Mode(true)
    l.hashPrefix('')

    c.includeSpinner = false

    r.when '/',
      controller: 'HomeCtrl'
      templateUrl: "html/_main.html"
      options:
        menu: 'home'
        title: 'Blühendes Österreich'

    r.when '/auth',
      controller: 'AuthCtrl'
      templateUrl: "html/_empty.html"
      options:
        menu: 'auth'

    r.when '/tickets-und-preise',
      controller: 'EmptyCtrl'
      templateUrl: "html/_besucher_tickets_preise.html"
      options:
        menu: 'tickets'
        title: 'Tickets & Preise'

    r.when '/oeffnungszeiten',
      controller: 'EmptyCtrl'
      templateUrl: "html/_offnungszeiten.html"
      options:
        menu: 'open_times'
        title: 'Öffnungszeiten'

    r.when '/gelaendeplan',
      controller: 'EmptyCtrl'
      templateUrl: "html/_gelandeplan.html"
      options:
        menu: 'plan'
        title: 'Geländeplan'

    r.when '/anreise-und-parken',
      controller: 'EmptyCtrl'
      templateUrl: "html/_anreise.html"
      options:
        menu: 'parken'
        title: 'Anreise & Parken'

    r.when '/besucheranfrage',
      controller: 'EmptyCtrl'
      templateUrl: "html/_besucher_kontakt.html"
      options:
        menu: 'visitors'
        title: 'Besucheranfrage'

    r.when '/faq',
      controller: 'EmptyCtrl'
      templateUrl: "html/_faq.html"
      options:
        menu: 'faq'
        title: 'FAQ'

    r.when '/unterkunfte',
      controller: 'EmptyCtrl'
      templateUrl: "html/_unterkunfte.html"
      options:
        menu: 'hotels'
        title: 'Unterkünfte'

    r.when '/agb',
      controller: 'EmptyCtrl'
      templateUrl: "html/_agb.html"
      options:
        menu: 'agb'
        title: 'AGB'

    r.when '/impressum',
      controller: 'EmptyCtrl'
      templateUrl: "html/_impressum.html"
      options:
        menu: 'impressum'
        title: 'Impressum'

    r.when '/datenschutz',
      controller: 'EmptyCtrl'
      templateUrl: "html/_datenschutz.html"
      options:
        menu: 'daten'

    r.when '/messeordnung',
      controller: 'EmptyCtrl'
      templateUrl: "html/_messeordnung.html"
      options:
        menu: 'messse'
        title: 'Messeordnung'

    r.when '/akkreditierung',
      controller: 'EmptyCtrl'
      templateUrl: "html/_akkreditierung.html"
      options:
        menu: 'press_a'
        title: 'Akkreditierung'

    r.when '/pressetexte',
      controller: 'EmptyCtrl'
      templateUrl: "html/_presse_berichte.html"
      options:
        menu: 'press_t'
        title: 'Pressetexte'

    r.when '/pressetexte/1',
      controller: 'EmptyCtrl'
      templateUrl: "html/_presse_berichte_detail-1.html"
      options:
        menu: 'press_t'
        title: 'Pressetexte'

    r.when '/pressetexte/:id',
      controller: 'EmptyCtrl'
      templateUrl: "html/_presse_berichte_detail.html"
      options:
        menu: 'press_t'
        title: 'Pressetexte'

    r.when '/presse-bildergalerien',
      controller: 'EmptyCtrl'
      templateUrl: "html/_presse_galerie.html"
      options:
        menu: 'press_g'
        title: 'Galerie'

    r.when '/presse-bildergalerien/:id',
      controller: 'GalleryViewCtrl'
      templateUrl: "html/_presse_galerie_detail.html"
      options:
        menu: 'press_g'
        title: 'Galerie'

    r.when '/merkliste',
      controller: 'FavsCtrl'
      templateUrl: "html/_merkliste.html"
      options:
        menu: 'favs'
        title: 'Merkliste'

    r.when '/werde-aussteller',
      controller: 'EmptyCtrl'
      templateUrl: "html/_aussteller_werden.html"
      options:
        menu: 'werde_a'
        title: 'Aussteller werden'

    r.when '/newsletter',
      controller: 'EmptyCtrl'
      templateUrl: "html/_newsletter.html"
      options:
        menu: 'newsletter'
        title: 'Newsletter'
    ###
    r.when '/programm',
      controller: 'ProgramsCtrl'
      templateUrl: "html/_programm_overview.html"
      reloadOnUrl: false
      options:
        menu: 'programs'
        title: 'Programm'

    r.when '/programm/:day',
      controller: 'ProgramsCtrl'
      templateUrl: "html/_programm_overview.html"
      reloadOnUrl: false
      options:
        menu: 'programs'
        title: 'Programm'

    r.when '/programm/:day/:track',
      controller: 'ProgramsCtrl'
      templateUrl: "html/_programm_overview.html"
      reloadOnUrl: false
      options:
        menu: 'programs'
        title: 'Programm'
    ###
    r.when '/programm/:day?/:track?/:slot?',
      controller: 'ProgramsCtrl'
      templateUrl: "html/_programm_overview.html"
      reloadOnUrl: false
      options:
        menu: 'programs'
        title: 'Programm'

    r.when '/serviceleistungen',
      controller: 'EmptyCtrl'
      templateUrl: "html/_aussteller_service.html"
      options:
        menu: 'service_a'
        title: 'Serviceleistungen'

    r.when '/werbung',
      controller: 'EmptyCtrl'
      templateUrl: "html/_aussteller_werbung.html"
      options:
        menu: 'werb_a'
        title: 'Werbung'
        
    r.when '/werbung/banner',
      controller: 'EmptyCtrl'
      templateUrl: "html/_aussteller_werbung_banner_generator.html"
      options:
        menu: 'werb_a'
        title: 'Banner Generator'

    r.when '/ausstellungsbereiche',
      controller: 'EmptyCtrl'
      templateUrl: "html/_themenbereiche.html"
      options:
        menu: 'aus_ber'
        title: 'Ausstellungsbereiche'

    r.when '/messe-bildergalerien',
      controller: 'EmptyCtrl'
      templateUrl: "html/_galerie_aus.html"
      options:
        menu: 'messe_g'
        title: 'Galerie'

    r.when '/messe-bildergalerien/:id',
      controller: 'GalleryViewCtrl'
      templateUrl: "html/_galerie_detail_aus.html"
      options:
        menu: 'messe_g'
        title: 'Galerie'

    r.when '/neuheiten',
      controller: 'NewsCtrl'
      templateUrl: "html/_neuheit_overview.html"
      options:
        menu: 'news'
        title: 'Neuheiten'

    r.when '/neuheiten/:id',
      controller: 'NewsEditCtrl'
      templateUrl: "html/_neuheit_detail.html"
      options:
        menu: 'news_d'
        title: 'Neuheiten'

    r.when '/profil',
      controller: 'ProfileCtrl'
      templateUrl: "html/_profil.html"
      options:
        menu: 'profile'
        title: 'Profil'

    r.when '/registrierung',
      controller: 'RegistrationCtrl'
      templateUrl: "html/_anmelden.html"
      options:
        menu: 'register'
        title: 'Registrierung'

    r.when '/login',
      controller: 'EmptyCtrl'
      templateUrl: "html/_anmelden.html"
      options:
        menu: 'login'
        title: 'Besucher Login'

    r.when '/passwort-vergessen',
      controller: 'PasswordCtrl'
      templateUrl: "html/_forgot.html"
      options:
        menu: 'forgot'
        title: 'Passwort vergessen'

    r.when '/passwort-confirm',
      controller: 'ConfirmCtrl'
      templateUrl: "html/_confirm.html"
      options:
        menu: 'confirm'

    r.when '/ausstelleranfrage',
      controller: 'EmptyCtrl'
      templateUrl: "html/_aussteller_kontakt.html"
      options:
        menu: 'aus_con'
        title: 'Ausstelleranfrage'

    r.when '/aussteller',
      controller: 'ExhibitorsListCtrl'
      templateUrl: "html/_ausstellerliste_box.html"
      options:
        menu: 'exhibitors'
        topc: 'all'
        title: 'Ausstellerverzeichnis'

    r.when '/aussteller/:id',
      controller: 'ExhibitorsViewCtrl'
      templateUrl: "html/_ausstellerdetail_standard.html"
      options:
        menu: 'list'
        title: 'Ausstellerverzeichnis'

    r.when '/aussteller/:id/:action',
      controller: 'ExhibitorsActionCtrl'
      templateUrl: "html/_empty.html"
      options:
        menu: 'list'
        title: 'Ausstellerverzeichnis'

    r.when '/suche',
      controller: 'SearchCtrl'
      templateUrl: "html/_suche.html"
      options:
        menu: 'search'

    r.when '/home',
      controller: 'HomeCtrl'
      templateUrl: "html/home/index.html?v=1"
      options:
        menu: 'home'

    r.when '/soon',
      controller: 'EmptyCtrl'
      templateUrl: "html/empty/index.html?v=1"
      options:
        menu: 'soon'


    r.when '/neuheiten/:entry_id',
      controller: 'NewsEditCtrl'
      templateUrl: "html/news/edit.html?v=1"
      options:
        menu: 'news'
        title: 'Neuheiten'


    r.when '/testlighting',
      controller: 'TestChatCtrl'
      templateUrl: "html/admin/chats.html?v=1"
      options:
        menu: 'admin'

    r.otherwise
      redirectTo: '/'

  ]